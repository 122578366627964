import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import { industries, services,techmenu } from "./utils";

export const Header = () => {
  const location = useLocation();
  const [small , setSmall]= useState(false)
  useEffect(() => {
    if (location.hash) {
      scroller.scrollTo(location.hash.substring(1), {
        duration: 100,
        offset: -20,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }
  }, [location]);

  // const services = [
  //   {
  //     id: 1,
  //     title: 'Custom Software Development',
  //     link: '/custom-development',
  //   },
  //   {
  //     id: 2,
  //     title: 'Web Development',
  //     link: '/web-development',
  //   },
  //   {
  //     id: 3,
  //     title: 'Mobile App Development',
  //     link: '/app-development',
  //   },
  //   {
  //     id: 4,
  //     title: 'Product Development',
  //     link: '/product-development',
  //   },
  //   {
  //     id: 5,
  //     title: 'Dedicated Team',
  //     link: '/dedicated-development',
  //   },
  //   {
  //     id: 6,
  //     title: 'SAP Services',
  //     link: '/sap-development',
  //   },
  //   {
  //     id: 7,
  //     title: 'Testing & QA',
  //     link: '/testing',
  //   },
  // ];
  // const industries = [
  //   {
  //     id: 1,
  //     title: 'Finance',
  //     link: '/finance',
  //   },
  //   {
  //     id: 2,
  //     title: 'Insurance',
  //     link: '/insurance',
  //   },
  //   {
  //     id: 3,
  //     title: 'Education',
  //     link: '/education',
  //   },
  //   {
  //     id: 4,
  //     title: 'Energy & Utilities',
  //     link: '/energyutilities',
  //   },
  //   {
  //     id: 5,
  //     title: 'Healthcare',
  //     link: '/healthcare',
  //   },
  //   {
  //     id: 6,
  //     title: 'Media & Entertainment',
  //     link: '/media',
  //   },
  //   {
  //     id: 7,
  //     title: 'Oil & Gas, Mining',
  //     link: '/oilgas',
  //   },
  //   {
  //     id: 8,
  //     title: 'Retail & eCommerce',
  //     link: '/retail',
  //   },
  //   {
  //     id: 9,
  //     title: 'Logistics & Distribution',
  //     link: '/logistics',
  //   },
  //   {
  //     id: 10,
  //     title: 'Travel & Hospitality',
  //     link: '/travel',
  //   },
  //   {
  //     id: 11,
  //     title: 'Public Sector',
  //     link: '/publicsector',
  //   },
  // ];


  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window?.pageYOffset > 50)
      );
    }

  }, []);

  return (
    <section className={`head-section ${small ? "sticky-header" : ""}`}>
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
           
            <NavLink className="navbar-brand" to="/">
              <img src="images/Rise_logo.svg" alt="" style={{ width: "160px" }} />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                  <i class="fa fa-home"></i>
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <NavLink className="nav-link dropdown-toggle text-black" style={{color:'rgba(0, 0, 0, 0.7)'}} to=""  aria-expanded="false">
                    Services
                  </NavLink>
                  <ul className="dropdown-menu">
                   {services?.map((service)=> ( <li key={service.id}><NavLink className="dropdown-item" to={service.link}>{service.title}</NavLink></li>))}
                   
                  </ul>
                </li>
               
                <li className="nav-item dropdown">
                  <NavLink className="nav-link dropdown-toggle text-black" to="" style={{color:'rgba(0, 0, 0, 0.7)'}}  aria-expanded="false">
                    Technologies
                  </NavLink>
                  <div className="dropdown-menu">
                  <ul  style={{maxWidth:'610px',display:'flex'}}>
                   {techmenu?.map((tech,index)=> ( <li key={index}>
                    <h4 className="p-3 pb-0 pt-0">{tech.title}</h4>
                     <ul>
                        {tech.subItems.map((subtech)=> (
                          <li><NavLink className="dropdown-item" to={subtech.link}>{subtech.name}</NavLink></li>
                        ))}
                     </ul>
                   </li>))}
                  </ul>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <NavLink className="nav-link dropdown-toggle text-black" to="" style={{color:'rgba(0, 0, 0, 0.7)'}}  aria-expanded="false">
                    Industries
                  </NavLink>
                  <ul className="dropdown-menu">
                   {industries?.map((industry)=> ( <li key={industry.id}><NavLink className="dropdown-item" to={industry.link}>{industry.title}</NavLink></li>))}
                  </ul>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link text-black" to="/about" >
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link text-black" to="/contactus">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
};


