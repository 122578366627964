import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <React.Fragment>
      <footer className="footer-08">
      <div className="container">
        <div className="row" style={{ alignItems: "start" }}>
          <div className="col-md-9 py-5">
            <div className="row" style={{ alignItems: "start" }}>
              <div
                className="col-md-4 mb-md-0 mb-4 ml-3"
                style={{ paddingLeft: "65px" }}
              >
                <img src="images/Rise_logo.svg" alt="" style={{ width: "180px" }} />
                <p className="mt-5 mb-5">
                "With attention to detail at every step, our experienced 
                recruiters and consultants specialize in forming impactful connections 
                between employers and talent."
                </p>
                {/* <button className="btn">Know more</button> */}
              </div>
              <div className="col-md-8">
                <div
                  className="row justify-content-center"
                  style={{ alignItems: "start" }}
                >
                  <div className="col-md-12">
                    <div className="row" style={{ alignItems: "start" }}>
                      <div className="col-md-4 mb-md-0 mb-4">
                        <h2 className="footer-heading">Services</h2>
                        <ul className="list-unstyled">
                          <li className="footer-item">
                            <Link to="/custom-development" className="py-1 d-block ">
                              Customer Software Development
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/web-development" className="py-1 d-block">
                            Web Development
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/app-development" className="py-1 d-block">
                            Mobile App Development
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/product-development" className="py-1 d-block">
                            Product Development
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/dedicated-development" className="py-1 d-block">
                            Dedicated Team
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/sap-development" className="py-1 d-block">
                            SAP Services
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/testing" className="py-1 d-block">
                            Testing & QA
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4 mb-md-0 mb-4">
                        <h2 className="footer-heading">Technologies</h2>
                        <ul className="list-unstyled">
                          <li className="footer-item">
                            <Link to="/technologies/dotnot" className="py-1 d-block">
                              .Net
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/technologies/java-development" className="py-1 d-block">
                              Java
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/technologies/angular-development-services" className="py-1 d-block">
                              Angular
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/technologies/react-development-services" className="py-1 d-block">
                              React
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/technologies/iphone-development" className="py-1 d-block">
                              iOS
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/technologies/android-app-development" className="py-1 d-block">
                              Android
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/technologies/sharepoint-development" className="py-1 d-block">
                              Share Point
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/technologies/cloud-and-devops" className="py-1 d-block">
                              Cloud & Devops
                            </Link>
                          </li>
                          
                         
                          
                        </ul>
                      </div>
                      <div className="col-md-4 mb-md-0 mb-4">
                        <h2 className="footer-heading">Contact Details</h2>
                        <ul className="list-unstyled">
                          <li className="footer-item">
                            <Link to="/" className="py-1 d-block">
                              Careers
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/" className="py-1 d-block">
                              Contact Us
                            </Link>
                          </li>
                          
                          <li className="footer-item">
                            <Link to="/" className="py-1 d-block">
                              Privacy Policy
                            </Link>
                          </li>
                          <li className="footer-item">
                            <Link to="/" className="py-1 d-block">
                              Terms & Conditions
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div className="col-md-3 aside-stretch-right pl-lg-5">
            <h2 className="footer-heading footer-heading-white text-white">
              <img src="images/phone.svg" alt="" /> Let’s Talk
            </h2>
            <div className="lets-talk">
            <div className="d-flex gap-2">
              <img src="images/mail.svg" alt="" style={{ width: "40px" }} />
              <div>
                <b>Business enquiries</b>
                <p className="m-0">
                  <a href="mailto:info@riseprosoftsolutions.com">
                    info@riseprosoftsolutions.com
                  </a>
                </p>
              </div>
            </div>
            <div className="d-flex gap-2 mt-4">
              <img src="images/telegram.svg" alt="" style={{ width: "40px" }} />
              <div>
                <b>Business enquiries </b>
                <p className="m-0">
                  <a href="mailto:info@riseprosoftsolutions.com">
                    @riseprosoft
                  </a>
                </p>
              </div>
            </div>
            <div className="d-flex gap-2 mt-4">
              <img src="images/linkedin.png" alt="" style={{ width: "48px" }} />
              <img src="images/facebook.png" alt="" style={{ width: "48px" }} />
              <img
                src="images/instagram.png"
                alt=""
                style={{ width: "48px" }}
              />
            </div>
            {/* <div className="input-group mt-4">
             
              <input
                type="text"
                className="form-control"
                id="inlineFormInputGroup"
                placeholder="Enter Your Email"
              />
               <div className="input-group-prepend">
                <div className="input-group-text">submit</div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div className="copy-right-section">
       <div className="container">
       <div className="row mt-md-5" style={{ alignItems: "start" }}>
              <div className="col-md-12">
                <p className="copyright">
                  Copyright ©{year} All rights reserved
                </p>
              </div>
            </div>
       </div>
    </div>
    </React.Fragment>
  );
};
